// To see this message, follow the instructions for your Ruby framework.
//

import imagesLoaded from 'imagesloaded'
import Masonry from 'masonry-layout'
// import Waypoint from 'waypoints'
// import 'waypoints'
import { tns } from 'tiny-slider';
import { createFluidBox } from '../module/fluidbox';

import 'tiny-slider/dist/tiny-slider.css'
import '../styles/fluidbox.scss'

// When using a plain API, perhaps it's better to generate an HTML entrypoint
// and link to the scripts and stylesheets, and let Vite transform it.
console.log('Vite ⚡️ Ruby')

// Example: Import a stylesheet in <sourceCodeDir>/index.css
// import '~/index.css'

// import debounce from 'lodash/debounce'

// /!\ /!\ /!\ /!\
// when changing page, REMOVE EVENT LISTENER (destroy / unbind fluidbox.js) maybe same for masonry
// /!\ /!\ /!\ /!\

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Navigation

// Global vars
const body = document.querySelector('body')
const pageElement = document.querySelector('.page')
let navTarget = body.dataset.pageUrl
let docTitle = document.title
let history = window.history


const makeEaseInOut = (timing) => {
  return function (timeFraction) {
    if (timeFraction < .5)
      return timing(2 * timeFraction) / 2;
    else
      return (2 - timing(2 * (1 - timeFraction))) / 2;
  }
}
function circ (timeFraction) {
  return 1 - Math.sin(Math.acos(timeFraction));
}
function animate ({ timing, draw, duration }) {
  let start = performance.now();

  requestAnimationFrame(function animate (time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    // calculate the current animation state
    let progress = timing(timeFraction)

    draw(progress); // draw it

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }

  });
}

function scrollToTop (duration = 400) {
  const circEaseInOut = makeEaseInOut(circ);
  let to = 0
  let from = window.scrollY
  let scrollX = window.scrollX
  if (from === to) return

  animate({
    duration,
    timing: circEaseInOut,
    draw: function(progress) {
      let result = window.scrollY - from * progress
      window.scrollTo(scrollX, result)
    }
  })
}

const createElmtWithClass = (elementTag, className) => {
  const elmt = document.createElement(elementTag)
  elmt.classList.add(className)
  return elmt
}


window.addEventListener('popstate', function onPopState (event) {
  console.log('popstate event, calling loadPage')
  loadPage(event.state.path)
})


const createLibrariesElements = () => {
  const currentElements = []
  const currentObservers = []

  return {
    addElement: (element) => {
      currentElements.push(element)
    },
    addObserver: (observer) => {
      currentObservers.push(observer)
    },
    destroyAllElements: () => {
      currentElements.forEach(element => {
        element.destroy()
      })
      currentObservers.forEach(observer => {
        observer.disconnect()
      })
    }
  }
}

const librariesElement = createLibrariesElements()


function loadPage (pageUrl) {
  console.log('loadpage', pageUrl)
  if (body.classList.contains('loading')) return

  body.classList.add('loading')
  // maybe catch error in case
  fetch(pageUrl)
    .then((response) => {
      response.text().then(text => {
        librariesElement.destroyAllElements()

        const loadedPage = document.createElement('div')
        loadedPage.innerHTML = text
        const loadedContent = loadedPage.querySelector('.page__content')
        document.querySelector('.page-loader').appendChild(loadedContent)
        
        scrollToTop(300)
        // Find transition time
        const transitionTime = 100;

        setTimeout(function () {

          pageElement.querySelector('.page__content').replaceWith(loadedContent)
          
          body.dataset.pageUrl = window.location.pathname
          navTarget = body.dataset.pageUrl
          docTitle = loadedContent.dataset.pageTitle
          document.title = docTitle

          // Run page functions
          try {
            pagesFunction()
          } catch(err) {
            console.error(err)
          }

        }, transitionTime);
      })
    }).catch((err) => {
      loadPage('/404')
    })
}

if (body.classList.contains('ajax-loading')) {
  console.log('body has ajax-loading')
  document.addEventListener('click', function onClick (e) {
    console.log('click')
    if (e.target.matches('a')) {
      e.preventDefault()
      const elmt = e.target
      const targetHref = elmt.getAttribute('href')
      if (targetHref.includes('http') || targetHref.includes('pdf')) {
        // Go to the external link
        window.open(targetHref, '_blank')
      }
      // If we don't want to use ajax
      else if (elmt.classList.contains('js-no-ajax')) {
        // Use the given link
        window.location = targetHref
      }
      // if it's a contact modal
      else if (elmt.classList.contains('js-contact')) {
        // Open contact modal
        document.querySelector('.modal--contact').classList.add('modal--on')
      }
      else if (elmt.classList.contains('js-signup')) {
        // Open signup modal
        document.querySelector('.modal--signup').classList.add('modal--on')
      }
      // If link is handled by some JS action – e.g. fluidbox
      else if (elmt.classList.contains('.gallery__item__link')) {
        // noop?
        // Let JS handle it
      }
      // If link is internal
      else {
        // Change navTarget
        navTarget = targetHref
        // Switch the URL via History
        history.pushState({ path: targetHref }, docTitle, targetHref)
        console.log(history.length)
        console.log('just pushed state, calling loadPage')
        loadPage(targetHref)
      }
    } else {
      const postElement = e.target.closest('.post')
      if (postElement) {
        const targetPost = postElement.querySelector('.post__title a').getAttribute('href')
        navTarget = targetPost
        history.pushState({ name: targetPost }, docTitle, targetPost)
        console.log(history.length)
        console.log('clicked on post body, launched loadPage')
        loadPage(targetPost)
        
      }
    }
  })
} else {
  // Click anywhere on the post to go to the link
  document.addEventListener('click', function onClick (e) {
    const postElement = e.target.closest('.post')
    if (postElement) {
      window.location = postElement.querySelector('.post__title a').getAttribute('href')
    }
  })
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Listing post click

// Click anywhere on the post to go to the link
// document.addEventListener('click', function onClick (e) {
//   const postElement = e.target.closest('.post')
//   if (postElement) {
//     const targetPost = postElement.querySelector('.post__title a').getAttribute('href')
//     if (body.classList.contains('ajax-loading')) {
//       navTarget = targetPost
//       history.pushState({ name: targetPost }, docTitle, targetPost)
//       console.log(history.length)
//       console.log('clicked on post body, launched loadPage')
//       loadPage(targetPost)
//     } else {
//       window.location = targetPost
//     }
//     lastPage = targetPost
//   }
// })

// Modals

const modalTypes = ['contact', 'signup']

modalTypes.forEach(modalType => {
  document.querySelectorAll(`.js-${modalType}`).forEach(elmt => {
    elmt.addEventListener(function onClick (event) {
      event.preventDefault()
      document.querySelector(`.modal-${modalType}`).classList.add('modal--on')
    })
  })
})

const pagesFunction = () => {

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Show content

  const heroImageElement = document.querySelector('.hero__image')

  if (heroImageElement) {
    imagesLoaded(heroImageElement, { background: true}, () => {
      // TODO SAVE THOSE VARIABLES AND DESTROY THEM WHEN LEAVING PAGE

      const portfolioWrapElement = document.querySelector('.portfolio-wrap')
      if (portfolioWrapElement) {
        imagesLoaded(portfolioWrapElement,  () => {
          const portfolioMasonry = new Masonry(portfolioWrapElement, {
            itemSelector: '.portfolio-item',
            transitionDuration: 0
          })
          librariesElement.addElement(portfolioMasonry)
        })
      }

      const blogWrapElement = document.querySelector('.blog-wrap')
      if (blogWrapElement) {
        imagesLoaded(blogWrapElement, () => {
          const blogMasonry = new Masonry(blogWrapElement, {
            itemSelector: '.blog-post',
            transitionDuration: 0
          })
          librariesElement.addElement(blogMasonry)
        })
      }

      body.classList.remove('loading', 'menu--open')
    })
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Active links

  // Switch active link states
  const activeLinkElement = document.querySelector('.active-link')
  activeLinkElement && activeLinkElement.classList.remove('active-link')
  console.log(navTarget)
  const nextActive = document.querySelector(`a[href$="${navTarget}"]`)
  nextActive && nextActive.classList.add('active-link')

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Galleries
  // Destroy all existing waypoints

  // Waypoint.destroyAll()

  // Set up count for galleries to give them unique IDs
  let galleryCount = 0

  const galleryElements = document.querySelectorAll('.gallery')

  galleryElements.forEach(element => {

    galleryCount++
    element.id = `gallery-${galleryCount}`

    // Gallery columns
    const galleryCols = element.dataset.columns

    // Set up gallery container
    const galleryWrapper = createElmtWithClass('div', 'gallery__wrap')
    element.appendChild(galleryWrapper)

    const imagesElements = element.querySelectorAll('img')
    imagesElements.forEach(elmnt => {
      const galleryItemElement = createElmtWithClass('div', 'gallery__item')
      const galleryLinkElement = createElmtWithClass('a', 'gallery__item__link')
      galleryLinkElement.href = elmnt.src

      galleryLinkElement.appendChild(elmnt)
      galleryItemElement.appendChild(galleryLinkElement)
      galleryWrapper.appendChild(galleryItemElement)
    })
    imagesLoaded(element, () => {
      if (galleryCols === '1') {
        // Add carousel class to gallery
        element.classList.add('gallery--carousel')
        // Add owl styles to gallery wrap
        
        // Use carousel
        // const container = document.createElement('div')
        const sliderCarousel = tns({
          container: galleryWrapper,
          mode: 'gallery',
          items: 1,
          loop: true,
          mouseDrag: true,
          touch: true,
          controls: false,
          navPosition: 'bottom',
          autoplay: true,
          autoplayButtonOutput: false,
          autoplayTimeout: 6000,
          autoHeight: true,
          speed: 1000,
          animateIn: 'tns-fadeInSoft',
          animateOut: 'tns-fadeOutSoft',
        })


        if ('IntersectionObserver' in window) {
          console.log('intersection yes')
          const sliderEl = sliderCarousel.getInfo().container

          const sliderObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
              if (!entry.isIntersecting) {
                sliderCarousel.pause()
                console.log('pause')
              } else {
                console.log('play')
                sliderCarousel.play()
              }
            })
          })

          sliderObserver.observe(sliderEl)
          librariesElement.addObserver(sliderObserver)
        }
      } else {
        element.classList.add('gallery--grid')
        // Use masonry layout
        const masonryGallery = new Masonry(galleryWrapper, {
          itemSelector: '.gallery__item',
          transitionDuration: 0
        })
        librariesElement.addElement(masonryGallery)
          
        // Init fluidbox
        document.querySelectorAll('.gallery__item__link')
          .forEach(elmnt => {
            const fluidbox = createFluidBox(elmnt, {
              loader: true
            })
          librariesElement.addElement(fluidbox)
        })
      }
      // Show gallery once initialized
      element.classList.add('gallery--on')
    })
  })

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Images

  document.querySelectorAll('.single p > img').forEach(imgElmt => {
    const wrapImg = createElmtWithClass('div', 'image-wrap')
    wrapImg.appendChild(imgElmt)
    imgElmt.parentElement.replaceWith(wrapImg)
  })

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Videos


  document.querySelectorAll('.single iframe').forEach(iframeElmt => {
    const src = iframeElmt.getAttribute('src')
    if (src && (src.includes('youtube') || src.includes('vimeo'))) {
      const wrapIframeElmt = createElmtWithClass('div', 'video-wrap')
      const wrapVideoElmt = createElmtWithClass('div', 'video')
      wrapVideoElmt.style.paddingBottom = `${(iframeElmt.height / iframeElmt.width) * 100}%`

      wrapIframeElmt.appendChild(wrapVideoElmt)
      iframeElmt.parentElement.insertBefore(wrapIframeElmt, iframeElmt)
      wrapVideoElmt.appendChild(iframeElmt)
    }
  })

}

try {
  pagesFunction()
} catch(err) {
  console.error(err)
}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Menu

document.querySelectorAll('.js-menu-toggle').forEach(element => {
  element.addEventListener('click', function onClick () {
    body.classList.toggle('menu--open')
  })
})

document.querySelectorAll('.menu__list__item__link').forEach(element => {
  element.addEventListener('click', function onClick () {
    const menuElmt = document.querySelector('.menu')
    if (menuElmt.classList.contains('menu--open')) {
      menuElmt.classList.remove('menu--open')
    }
  })
})




// Submit form
document.addEventListener('submit', function onSubmit (e) {
  if (e.target.matches('#contact-form')) {
    
    // Clear previous classes
    document.querySelector('.contact-form__item--error').classList.remove('.contact-form__item--error')

    // Get form elements
    const emailField = document.querySelector('.contact-form__input[name="email"]')
    const nameField = document.querySelector('.contact-form__input[name="name"]')
    const messageField = document.querySelector('.contact-form__textarea[name="message"]')
    const gotchaField = document.querySelector('.contact-form__gotcha')

    // Validate email
    if (emailField.value === '') {
      emailField.closest('.contact-form__item').classList.add('contact-form__item--error');
    }

    // Validate name
    if (nameField.value === '') {
      nameField.closest('.contact-form__item').classList.add('contact-form__item--error');
    }

    // Validate message
    if (messageField.value === '') {
      messageField.closest('.contact-form__item').classList.add('contact-form__item--error');
    }

    // If all fields are filled, except gotcha
    if (emailField.value !== '' && nameField.value !== '' && messageField.value !== '' && gotchaField.value.length === 0) {

      // Submit the form!
    }

    else {

      // Stop submission
      e.preventDefault();
    }
  }
})
